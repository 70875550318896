import * as React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/ui/Seo';
import ProgramDetailLayout from '../components/ui/Layout/ProgramDetailLayout';
import ProgramName from '../components/ProgramDetail/ProgramName';
import FeatureImage from '../components/ProgramDetail/FeatureImage';

import SingleLine from '../components/ProgramDetail/Responses/SingleLine';
import List2Col from '../components/ProgramDetail/Responses/List2Col';
import SingleLineList from '../components/ProgramDetail/Responses/SingleLineList';
import DoubleLineList from '../components/ProgramDetail/Responses/DoubleLineList';
import SingleLine3Col from '../components/ProgramDetail/Responses/SingleLine3Col';
import * as DSIcon from '../svgs/direct-service.svg';
import List1Col from '../components/ProgramDetail/Responses/List1Col';
import SuccessStories from '../components/ProgramDetail/Responses/SuccessStories';
import { PRISON_ALTERNATIVE_MENU } from '../utils/constants';

import s from './ProgramDetail.module.scss';
import VideoPlayer from '../components/ui/VideoPlayer/VideoPlayer';
import SingleLineLinkList from '../components/ProgramDetail/Responses/SingleLineLinkList';
import Quote from '../components/ProgramDetail/Responses/Quote';
import YesNo from '../components/ProgramDetail/Responses/YesNo';
import TheoryOfChange from '../components/ProgramDetail/TheoryOfChange';
import LineAndDownArrow from '../components/ui/LineAndDownArrow/LineAndDownArrow';

const AlternativesProgramDetail = ({
  // keep to pass variables set in gatsby-node.js
  pageContext,
  data,
}: {
  pageContext: { programName: string; slug: string };
  data: {
    allContentfulAlternativesToPrisonQuestions: { edges: any };
    allContentfulProgram: { edges: any };
    allContentfulVideo: { edges: any };
  };
}) => {
  // console.log('Alternatives Program Detail');
  if (
    data === undefined ||
    !data.allContentfulAlternativesToPrisonQuestions ||
    data.allContentfulAlternativesToPrisonQuestions.edges.length === 0 ||
    !data.allContentfulProgram ||
    data.allContentfulProgram.edges.length === 0
  ) {
    return null;
  }
  let survey = data.allContentfulAlternativesToPrisonQuestions.edges[0].node;
  let program = data.allContentfulProgram.edges[0].node;
  let video =
    data.allContentfulVideo.edges[0] &&
    data.allContentfulVideo.edges[0].node.video.localFile.url;
  let clientQuote =
    program.quotes && program.quotes[0] && program.quotes[0].quote.quote;
  let quoteSource = program.quotes && program.quotes[0].source;
  let laws =
    survey.mainGovernmentLaws &&
    survey.mainGovernmentLaws.map(
      (l: any) => l.childContentfulGovernmentLawLawTextNode.law
    );
  let links = program.linksAndDownloads;

  const hasImages = (): boolean => {
    return program.imageLinks !== null;
  };

  const hasVideos = (): boolean => {
    return (
      video !== undefined ||
      (program.videoLinks && program.videoLinks.length > 0)
    );
  };

  function getVideo() {
    let vid;
    if (video) {
      vid = video;
    } else {
      vid = program.videoLinks[0];
    }

    return vid;
  }
  return (
    <ProgramDetailLayout menuItems={PRISON_ALTERNATIVE_MENU}>
      <SEO title={survey.programName} />
      <p className="link-icon text-right">
        <a style={{ fontSize: '24px' }}>
          <DSIcon style={{ marginRight: '16px' }} /> Direct Services
          <br />
          <span style={{ fontSize: '18px' }}>{program.programSubType}</span>
        </a>
      </p>
      <article className={'module-program'}>
        <header style={{ borderBottomWidth: 0 }}>
          <ProgramName programName={survey.programName} />
        </header>
        {hasImages() && !hasVideos() && (
          <FeatureImage
            programName={survey.programName}
            images={program.imageLinks}
          />
        )}
        {hasVideos() && <VideoPlayer url={getVideo()} />}

        {clientQuote && <Quote quote={clientQuote} source={quoteSource} />}

        {/* PROGRAM PROFILE */}
        <div id={'profile'}>
          <p className={s.section__title}>Program Profile</p>
          {program.implementingOrganization && (
            <SingleLine
              question={'Implementing organization'}
              answer={program.implementingOrganization}
            />
          )}

          <SingleLine3Col
            headings={['Country', 'Headquarters', 'Start Year']}
            responses={[
              program.countryRegion,
              program.headquarters,
              program.startYear,
            ]}
          />

          {program.website && (
            <SingleLine question={'website'} answer={program.website} link />
          )}

          {program.description && program.description.description && (
            <SingleLine
              question={'Program Description'}
              answer={program.description.description}
            />
          )}
        </div>
        {/* THE BIG PICTURE */}
        <div id={'bigPicture'}>
          <p className={s.section__title}>The Big Picture</p>
          {survey
            .childContentfulAlternativesToPrisonQuestionsProblemAddressedTextNode
            .problemAddressed && (
            <SingleLine
              question={'Problem Addressed'}
              answer={
                survey
                  .childContentfulAlternativesToPrisonQuestionsProblemAddressedTextNode
                  .problemAddressed
              }
            />
          )}
          {survey.programObjective && (
            <SingleLine
              question={'Program Objective'}
              answer={survey.programObjective}
            />
          )}

          {survey.whatIsLifeLikeInYourFacilityInFiveWords && (
            <SingleLineList
              question={'What is life like in your facility in five words?'}
              responses={survey.whatIsLifeLikeInYourFacilityInFiveWords}
            />
          )}
          {survey.childContentfulAlternativesToPrisonQuestionsWhatWouldMakeYourWorkUnnecessaryTextNode && (
            <SingleLine
              question={'What would make your work unnecessary?'}
              answer={
                survey
                  .childContentfulAlternativesToPrisonQuestionsWhatWouldMakeYourWorkUnnecessaryTextNode
                  .whatWouldMakeYourWorkUnnecessary
              }
            />
          )}
          {survey.mainPracticalChallengesFaced && (
            <List2Col
              title={'Main Practical Challenges Faced'}
              responses={survey.mainPracticalChallengesFaced}
            />
          )}
          {laws && (
            <SingleLineList
              question={
                'Main government laws, policies, or programs that have led to positive change in the last 40 years:'
              }
              responses={laws}
            />
          )}
        </div>
        {/* Program strategies/Targets */}
        <div id={'beneficiariesAndTargets'}>
          <p className={s.section__title}>
            Program Beneficiaries/Target Groups
          </p>
          {survey.mainBeneficiaries && (
            <DoubleLineList
              title={'Main Beneficiaries'}
              responses={survey.mainBeneficiaries}
            />
          )}
          {survey.targetedGroups && (
            <DoubleLineList
              title={'Targeted Groups'}
              responses={survey.targetedGroups}
            />
          )}
        </div>
        {/* THEORY OF CHANGE */}
        <div id={'theoryOfChange'}>
          <p className={s.section__title}>Theory of Change</p>
          <TheoryOfChange />
          {survey.services && (
            <List2Col title={'Services'} responses={survey.services} />
          )}
          {survey.differenceFromTraditionalPrisons && (
            <SingleLineList
              question={"What's different from traditional prisons?"}
              responses={survey.differenceFromTraditionalPrisons}
            />
          )}
          {survey.outputs && (
            <>
              <LineAndDownArrow />
              <List2Col title={'Outputs'} responses={survey.outputs} />
            </>
          )}
          {survey.shortTermOutcomes && (
            <>
              <LineAndDownArrow />
              <SingleLineList
                question={'Short-Term Outcomes'}
                responses={survey.shortTermOutcomes}
              />
            </>
          )}
          {survey.longTermOutcomes && (
            <>
              <LineAndDownArrow />
              <SingleLineList
                question={'Long-Term Outcomes'}
                responses={survey.longTermOutcomes}
              />
            </>
          )}
          {survey.longTermChange && (
            <>
              <LineAndDownArrow />
              <SingleLineList
                question={'Long-Term Change'}
                responses={survey.longTermChange}
              />
            </>
          )}

          {survey.programRationale && (
            <SingleLineList
              question={'Program Rational'}
              responses={survey.programRationale}
            />
          )}
          {survey.ingredientsForSuccess && (
            <SingleLineList
              question={'Ingredients for Success'}
              responses={survey.ingredientsForSuccess}
            />
          )}
          {survey.strategiesForExpanding && (
            <SingleLineList
              question={'Strategies – if any – for expanding'}
              responses={survey.strategiesForExpanding.strategiesForExpanding}
            />
          )}
        </div>
        {/* Implementation */}
        <div id={'implementation'}>
          <p className={s.section__title}>Implementation</p>
          {survey.yearsImplemented && (
            <SingleLine
              question={'Years Implemented'}
              answer={survey.yearsImplemented}
            />
          )}
          {survey.outputsCompletedIn20172018 && (
            <List2Col
              title={'Outputs Completed in 2017/2018'}
              responses={survey.outputsCompletedIn20172018}
            />
          )}
          <SingleLine3Col
            headings={[
              'Participants in 2017',
              'Average length of time for services',
              '% of staff incarcerated or formerly incarcerated',
            ]}
            responses={[
              survey.participantsbeneficiariesIn2017,
              survey.averageLengthOfTimeForServices,
              survey.percentStaffIncarceratedformerlyIncarcerated,
            ]}
          />
          {survey.percentOfTotalTargetPopulationParticipating && (
            <SingleLineList
              question={
                'Estimate % of total incarcerated or formerly incarcerated population participating'
              }
              responses={survey.percentOfTotalTargetPopulationParticipating}
            />
          )}
          {hasImages() && hasVideos() && (
            <FeatureImage
              programName={survey.programName}
              images={program.imageLinks}
            />
          )}
        </div>
        {/* Cost */}
        <div id={'cost'}>
          <p className={s.section__title}>Cost</p>
          <SingleLine3Col
            headings={[
              'Annual Program Budget',
              'Budget Year',
              'Main Funding Source',
            ]}
            responses={[
              survey.annualProgramBudget,
              survey.budgetYear,
              survey.mainFundingSource,
            ]}
          />
        </div>
        {/* Evaluating Success */}
        <div id={'evaluatingSuccess'}>
          <p className={s.section__title}>Evaluating Success</p>
          {survey.dataCollected && (
            <List2Col
              title={'Data Collected'}
              responses={survey.dataCollected}
            />
          )}
          {survey.indicatorsTracked && (
            <List2Col
              title={'Indicators Tracked'}
              responses={survey.indicatorsTracked}
            />
          )}
          <YesNo
            question={'Evaluations Conducted'}
            answer={survey.evaluationConducted}
          />
          {survey.typeOfEvaluationsConducted && (
            <List1Col
              title={'Type of Evaluations Conducted'}
              responses={survey.typeOfEvaluationsConducted}
            />
          )}
          {survey.childContentfulAlternativesToPrisonQuestionsEvaluationFindingsTextNode && (
            <SingleLine
              question={'Evaluation Findings'}
              answer={
                survey
                  .childContentfulAlternativesToPrisonQuestionsEvaluationFindingsTextNode
                  .evaluationFindings
              }
            />
          )}
          {survey.evaluationWeblinks && (
            <SingleLineLinkList
              question={'Evaluation links'}
              responses={[survey.evaluationWeblinks]}
            />
          )}
          {survey.successStories && survey.successStories.length > 0 && (
            <SuccessStories
              question={'Success Stories'}
              stories={survey.successStories}
            />
          )}
        </div>
        {/* Additional Materials */}
        {links && (
          <div id={'additionalMaterials'}>
            <p className={s.section__title}>Additional Materials</p>
            <SingleLineLinkList
              question={'Links & Downloads'}
              responses={links ? links : 'N/A'}
            />
          </div>
        )}
      </article>

      <Link to="/">
        <p style={{ color: 'black' }}>Go back to the homepage</p>
      </Link>
    </ProgramDetailLayout>
  );
};

export default AlternativesProgramDetail;

export const query = graphql`
  query AlternativesToPrisonQuestions($programName: String!) {
    allContentfulProgram(filter: { programName: { eq: $programName } }) {
      edges {
        node {
          imageLinks
          videoLinks
          socialMedia
          website
          programSubType
          linksAndDownloads
          implementingOrganization
          countryRegion
          headquarters
          startYear
          programType
          description {
            description
          }
          quotes {
            quote {
              quote
            }
            source
            sourceTitle
          }
        }
      }
    }
    allContentfulVideo(
      filter: { program: { programName: { eq: $programName } } }
    ) {
      edges {
        node {
          video {
            localFile {
              url
            }
          }
        }
      }
    }
    allContentfulAlternativesToPrisonQuestions(
      filter: { programName: { eq: $programName } }
    ) {
      edges {
        node {
          programName
          childContentfulAlternativesToPrisonQuestionsProblemAddressedTextNode {
            problemAddressed
          }
          programObjective
          differenceFromTraditionalPrisons
          whatIsLifeLikeInYourFacilityInFiveWords
          childContentfulAlternativesToPrisonQuestionsWhatWouldMakeYourWorkUnnecessaryTextNode {
            whatWouldMakeYourWorkUnnecessary
          }
          mainPracticalChallengesFaced
          mainGovernmentLaws {
            childContentfulGovernmentLawLawTextNode {
              law
            }
          }
          mainBeneficiaries
          targetedGroups
          services
          percentOfTotalTargetPopulationParticipating
          whatsDifferentFromTraditionalPrisons
          outputs
          shortTermOutcomes
          longTermOutcomes
          longTermChange
          programRationale
          ingredientsForSuccess
          strategiesForExpanding {
            strategiesForExpanding
          }
          yearsImplemented
          outputsCompletedIn20172018
          participantsbeneficiariesIn2017
          averageLengthOfTimeOfResidenceInFacility
          averageLengthOfTimeForServices
          annualProgramBudget
          budgetYear
          mainFundingSource
          dataCollected
          indicatorsTracked
          evaluationConducted
          typeOfEvaluationsConducted
          evaluationWeblinks
          childContentfulAlternativesToPrisonQuestionsEvaluationFindingsTextNode {
            evaluationFindings
          }
          successStories {
            story {
              story
            }
          }
        }
      }
    }
  }
`;
